import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import CommonReviews from "../components/CommonReviews"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"
import reviews from "../reviews.json"

export default function ServicesTemplate({ data, pageContext }) {
  const {
    mdx: {
      frontmatter: { title, description },
      body,
    },
  } = data

  let testimonials
  const slugPiece = pageContext.slug.split("/")[3]
  if (reviews[slugPiece]) testimonials = reviews[slugPiece]
  else {
    if (slugPiece === "corrective-jaw") testimonials = reviews["facial-trauma"]
    else if (slugPiece === "impacted-teeth")
      testimonials = reviews["wisdom-teeth"]
    else if (slugPiece === "cleft-palate")
      testimonials = reviews["bone-grafting"]
    else if (slugPiece === "all-on-four-treatment")
      testimonials = reviews["referrals"]
    else testimonials = reviews["dental-implants"]
  }

  return (
    <Layout>
      <Seo title={title} description={description} />
      <section className="container" style={{ margin: "128px auto" }}>
        <MDXRenderer>{body}</MDXRenderer>
      </section>
      <CommonReviews displayReviews reviews={testimonials} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    mdx(fileAbsolutePath: { regex: $slug }) {
      frontmatter {
        title
        description
      }
      body
    }
  }
`
